<template>
  <v-select
    :id="`input-files-${_uid}`"
    v-model="selected"
    :disabled="disabled"
    :filterable="false"
    :options="files"
    @option:selected="onSelect"
  >
    <template slot="no-options">
      {{ title }}
    </template>
    <template #option="{ id, fileName }">
      <div class="d-center">
        <img :src="`/api/File/Download/${id}`" />
        {{ fileName }}
      </div>
    </template>
    <template #selected-option="{ id, fileName }">
      <div class="selected d-center">
        <img :src="`/api/File/Download/${id}`" />
        {{ fileName }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { get } from '@/helpers/api';

export default {
  name: 'FileSelectList',
  props: {
    disabled: Boolean,
    entityType: Number,
    entityId: Number,
    value: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: 'Sök efter fil'
    }
  },
  data() {
    return {
      files: [],
      selected: null
    };
  },
  watch: {
    value(newValue) {
      return !newValue
        ? (this.selected = null)
        : (this.selected = this.files.find((x) => x.id === this.value));
    }
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    async getFiles() {
      await get('File', `GetAllByType/${this.entityType}`).then((x) => {
        this.files = x.data;
        if (this.value) this.selected = x.data.find((x) => x.id === this.value);
      });
    },
    onSelect() {
      this.$emit('input', this.selected.id);
    }
  }
};
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}

.v-select .vs__dropdown-toggle {
  min-height: 38px;
}

.vs__selected {
  min-height: 100%;
}
</style>
