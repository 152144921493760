<template>
  <div style="margin-bottom: 7rem !important">
    <h1 v-if="!id">{{ form.id ? 'Ändra' : 'Skapa' }} artikel</h1>
    <hr />
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="input-group-articleNo"
        label="Artikelnr"
        label-for="input-articleNo"
      >
        <b-form-input
          id="input-articleNo"
          v-model="form.articleNo"
          type="text"
          placeholder="Ange artikelnr"
          :disabled="isSubmitting"
          autocomplete="off"
          aria-describedby="input-articleNo-help-block"
        />
        <b-form-text id="input-articleNo-help-block">
          <ul class="text-danger mb-0">
            <li v-for="message in validationByKey('ArticleNo')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-form-text>
      </b-form-group>

      <b-form-group id="input-group-name" label="Namn" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          type="text"
          placeholder="Ange namn"
          :disabled="isSubmitting"
          autocomplete="off"
          aria-describedby="input-name-help-block"
          required
        />
        <b-form-text id="input-name-help-block">
          <ul class="text-danger mb-0">
            <li v-for="message in validationByKey('Name')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-form-text>
      </b-form-group>

      <b-form-group
        id="input-group-symbol"
        label="Symbol"
        label-for="input-file-symbol"
      >
        <b-input-group>
          <FileSelectList
            v-model="form.fileSymbolId"
            :entity-type="'symbol' | getEntityTypeIdFromText"
            :title="'Sök efter symbol'"
            :disabled="isSubmitting"
          />
        </b-input-group>

        <b-form-text id="input-info-help-block">
          <ul class="text-danger mb-0">
            <li v-for="message in validationByKey('IconFileId')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-form-text>
      </b-form-group>

      <Base64Field
        v-model="form.imageAsBase64"
        :validation-messages="validationByKey('ImageAsBase64')"
        :disabled="isSubmitting"
      />
      <b-form-group
        id="input-group-editor"
        label="Info"
        label-for="input-editor"
      >
        <Editor id="input-editor" v-model="form.info" />
        <b-form-text id="input-editor-help-block">
          <ul class="text-danger mb-0">
            <li v-for="message in validationByKey('Info')" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-form-text>
      </b-form-group>

      <b-row class="m-0">
        <b-col md="4" sm="12">
          <b-form-group
            id="input-group-active"
            label="Aktiv"
            label-for="input-active"
          >
            <b-form-checkbox
              id="input-active"
              v-model="form.active"
              :disabled="isSubmitting"
              aria-describedby="input-active-help-block"
            >
              <ul class="text-danger mb-0">
                <li v-for="message in validationByKey('Active')" :key="message">
                  {{ message }}
                </li>
              </ul>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col md="4" sm="12">
          <b-form-group
            id="input-group-serviceNeed"
            label="Service"
            label-for="input-serviceNeed"
          >
            <b-form-checkbox
              id="input-serviceNeed"
              v-model="form.serviceNeed"
              :disabled="isSubmitting"
              aria-describedby="input-serviceNeed-help-block"
            >
              <ul class="text-danger mb-0">
                <li
                  v-for="message in validationByKey('ServiceNeed')"
                  :key="message"
                >
                  {{ message }}
                </li>
              </ul>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col v-if="showIsPublic" md="4" sm="12">
          <b-form-group
            id="input-group-isPublic"
            label="Publik"
            label-for="input-isPublic"
          >
            <b-form-checkbox
              id="input-isPublic"
              v-model="form.isPublic"
              :disabled="isSubmitting"
              aria-describedby="input-isPublic-help-block"
            >
              <ul class="text-danger mb-0">
                <li
                  v-for="message in validationByKey('IsPublic')"
                  :key="message"
                >
                  {{ message }}
                </li>
              </ul>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-alert :show="validationByKey('Other').length > 0" variant="danger">
        <ul class="text-danger mb-0">
          <li v-for="message in validationByKey('Other')" :key="message">
            {{ message }}
          </li>
        </ul>
      </b-alert>

      <b-alert
        :show="successMessage.length > 0"
        variant="success"
        class="mb-0 mt-4"
      >
        <h6 class="mb-0 text-center">
          <font-awesome-icon icon="info-circle" /> {{ successMessage }}
        </h6>
      </b-alert>

      <b-button
        v-b-tooltip.hover
        variant="primary"
        class="float-right mt-4"
        :class="{ 'btn-block': id }"
        :disabled="isSubmitting"
        type="submit"
        :title="isSubmitting ? 'Sparar...' : ''"
      >
        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { get, post } from '@/helpers/api';
import Editor from '@/components/Editor';
import Base64Field from '@/components/Base64Field';
import FileSelectList from '@/components/file/FileSelectList';

export default {
  name: 'SaveArticle',
  components: {
    Editor,
    Base64Field,
    FileSelectList
  },
  props: {
    id: Number
  },
  data() {
    return {
      showIsPublic: false,
      form: {
        id: null,
        articleNo: '',
        name: '',
        fileSymbolId: null,
        info: null,
        imageAsBase64: null,
        active: false,
        serviceNeed: false,
        isPublic: true
      },
      isSubmitting: false,
      successMessage: '',
      validationErrors: null
    };
  },
  computed: {
    ...mapState({
      userRoles: (state) => state.user.user.roles
    }),
    isEditMode() {
      return this.form.id > 0;
    }
  },
  watch: {
    userRoles: {
      handler(role) {
        // Sets flag depending on role.
        this.showIsPublic = role
          ? this.userRoles.includes('SuperAdmin')
          : false;
      },
      immediate: true
    }
  },
  async mounted() {
    // Get article if necessary.
    this.form.id = typeof this.id !== 'undefined' ? this.id : 0;

    if (this.isEditMode) {
      await get('Article', `Get?id=${this.form.id}`)
        .then((x) => {
          this.form = x.data;
        })
        .catch((x) => {
          alert(x.data);
        });
    }
  },
  methods: {
    async onSubmit() {
      // Resets.
      this.successMessage = '';
      this.validationErrors = null;
      this.isSubmitting = true;

      // Post.
      await post('Article', 'Save', this.form)
        .then((x) => {
          this.form.id = x.data.entityId;
          this.successMessage = `Sparade ${this.form.articleNo} ${this.form.name}`;
        })
        .catch((x) => {
          this.validationErrors = x.response.data.errors;
        });
      this.isSubmitting = false;

      // On created.
      if (!this.validationErrors && this.form.id) this.onCreated();
    },
    onCreated() {
      this.hasSavedSuccessfullly = true;
    },
    validationByKey(code) {
      return this.validationErrors ? this.validationErrors[code] || [] : [];
    },
    resetForm() {
      this.showIsPublic = false;
      this.form = {
        id: null,
        articleNo: null,
        name: '',
        fileSymbolId: null,
        info: null,
        imageAsBase64: null,
        active: false,
        serviceNeed: false,
        isPublic: true
      };
      this.validationErrors = null;
    },
    async confirm() {
      this.$bvModal
        .msgBoxConfirm('Förändringar har skett', {
          title: 'Är du säker?',
          size: 'sm',
          //buttonSize: 'sm',
          cancelVariant: 'primary',
          cancelTitle: 'Nej',
          okTitle: 'Ja',
          okVariant: 'danger',
          footerClass: 'p-2 justify-content-between',
          hideHeaderClose: false,
          centered: true
        })
        .then((confirmed) => {
          return confirmed;
        });
    }
  }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
</style>
